<template>
  <div class="risk-root">
    <div class="top">
      <van-cell-group title="风险披露">
        <template #title>
          <div class="title-wraper theme-color">
            <div class="title">风险披露</div>
            <div class="speed">
              <span
                :class="'span' + (speed === 1 ? ' active' : '') "
                @click="handleSpeed(1)">标准</span>
              <span
                :class="'span' + (speed === 1.5 ? ' active' : '') "
                @click="handleSpeed(1.5)">快速</span>
            </div>
          </div>
        </template>
        <div class="represent">
          <div class="image">
            <van-image
              width="90px"
              height="120px"
              fit="fill"
              src="https://forexmaster.oss-cn-beijing.aliyuncs.com/openAccount/image/%E9%99%88%E5%BD%92%E4%B8%80.jpg"
            />
          </div>
          <div class="name">
            <div>持牌代表：陈归一</div>
            <div>牌照编号：BQD372</div>
            <!-- <div>
              <span>总时间：{{duration}}</span><br>
              <span>播放时间：{{currentTime}}</span><br>
              <span>播放百分比：{{audioValue}}</span><br>
            </div> -->
          </div>
        </div>
      </van-cell-group>
        <audio
          v-if="form.risk_tell_type"
          :src="'https://forexmaster.oss-cn-beijing.aliyuncs.com/openAccount/resource/' + form.risk_tell_type +'.mp3'"
          ref="audio"
          @canplay="onAudioCanplay()"
          @pause="onAudioPause()"
          @playing="onAudioPlaying()"
          @ended="onAudioEnd()"
          @timeupdate="onAudioTimeupdate($event)"
          @durationchange="onAudioDurationChange($event)"
        />
        <div class="audio">
          <van-button
            round
            :icon="isPlaying ? 'pause-circle-o' : 'play-circle-o'"
            type="primary"
            class="playBtn"
            :disabled="canPlay ? false : true"
            @click="onAudioPlay"
          />
          <div class="theme-color">{{ secondToDate(currentTime) }}</div>
          <van-slider
            class="audioSlider"
            v-model="audioValue"
            bar-height="2px"
            disabled="true"
            @change="onSliderChange"
          />
          <div class="theme-color">{{ secondToDate(duration)  }}</div>
        </div>
    </div>
    <van-cell-group class="center" title="">
      <div class="content">
        <div class="title">
          <span>风险披露声明</span>
        </div>
        <div v-html="contentList[form.risk_tell_type]" />
      </div>
    </van-cell-group>
    <div class="bottom" style="width: 100%;text-align: center;">
      <van-button
        type="primary"
        style="margin-top: 20px"
        class="btn"
        @click="next"
        :disabled="disabledNext">
        下一步（签署声明)
      </van-button>
      <van-button class="btn" style="margin-top: 10px" @click="before">
        上一步
      </van-button>
    </div>
  </div>
</template>

<script>
import {
  Button, CellGroup, Image as VanImage,
  Slider,
} from 'vant';
import { useStore } from 'vuex';
import {
  onMounted,
  computed,
  ref,
  reactive,
  watch,
} from 'vue';
import secondToDate from '../../utils/secondToDate';
import httpApi from '../../utils/httpApi';
import httpAddApi from '../../utils/httpAddApi';

const contentList = [
  '',
  `
    尊敬的客户您好，我是陈归一，持有证监会发出的证券和期货交易牌照，我的牌照号码是BQD372，在您开通专业投资者资格之前，请您细读专业投资者附录I，我会简单为您说明一下风险声明的几个主要点， 阁下需因应自身的投资经验、目的、财政或相关状况审慎考虑投资产品或服务是否适合您。如果途中您有不明白的地方或疑问，请您联系蜂投证券的客服，我们会进一步说明。
    客户作为专业投资者，本公司将不会再提供以下信息：
    1、须向客户提供有关本公司和有关其雇员及其他代表其行事的人士的身份和受雇状况的资料。
    2、为客户完成交易后，须尽快向该客户确认有关该宗交易的重点。
    3、须向客户提供关于纳斯达克-美国证券交易所试验计划的资料文件。
    您有权给予蜂投证券不少于14天的书面通知以反对被视为专业投资者及要求撤回专业投资者的资格。除非及直至蜂投证券收到您本人有关的反对及撤回资格之书面通知，蜂投证券有权将您视为专业投资者。
    客户做ISDA的风险：
    1、客户需要明白，ISDA并非普通证券业务，也不是在证券公司交易；客户需要签署“客户代理协议Agency Agreement”，由证券公司转介到其关联公司做交易。
    2、做 ISDA业务，股票将会在证券公司关联公司名下，而非客户名下，客户仅享有经济权益。
    3、客户的亏损如果达到预警线，必须追加保证金；如果达到平仓线，将会被强制平仓。
    客户做衍生品交易的风险：
    1、对手风险：当衍生工具的发行方是协力厂商，如上市公司或金融机构，如果发行方遇到财务问题，并导致发行方的信用评级下降；或者如果他们由于偿付能力问题而倒闭，衍生工具的价值将受到影响，甚至可能失去所有的价值。
    2、市场风险：衍生产品的价格取决于标的资产的价值。通常情况下，标的资产的价格波动将直接影响衍生产品的价格。
    3、流动风险：一些衍生产品在到期之前， 可能难以出售和转换成现金。
    4、利率风险：利率的变化肯定会影响衍生产品的价值。
    5、杠杆风险：衍生权证价值的变化速度 可能会比标的产品价值的变化速度要快或要慢。在最坏的情况下，衍生权证的价值 可能会下降到零，持有人将失去他们的整个购买价格。
  `,
  `
    尊敬的客户您好，我是陈归一，持有证监会发出的证券和期货交易牌照，我的牌照号码是BQD372，在您的帐户开通之前，请您细读我们的期货客户交易协议书和风险声明，我会简单为您说明一下风险声明的几个主要点， 阁下需因应自身的投资经验、目的、财政或相关状况审慎考虑投资产品或服务是否适合您。如果途中您有不明白的地方或疑问，请您联系蜂投证券的客服，我们会进一步说明。
    期货及期权交易的风险：
    买卖期货合同或期权的亏损风险可以极大。在若干情况下，你所蒙受的亏损可能会超过最初存入的保证金数额。即使你设定了备用指示，例如“止损”或“限价”等指示，亦未必能够避免损失。市场情况可能使该等指示无法执行。你可能会在短时间内被要求存入额外的保证金。假如未能在指定的时间内提供所需数额，你的未平仓合同可能会被平仓。然而，你仍然要对 你的帐户内任何因此而出现的短欠数额负责。因此，你在买卖前应研究及理解期货合同及期权，以及根据本身的财政状况及投资目标，仔细考虑这种买卖是否适合你。如果你买卖期权，便应熟悉行使期权及期权到期时的程序，以及你在行使期权及期权到期时的权利与责任。 本声明并不涵盖买卖期货及期权的所有风险及其他重要事宜。就风险而言，你在进行任何上述交易前，应先了解将订立的合同的性质（及有关的合同关系）和你就此须承担的风险程度。期货及期权买卖对很多公众投资者都并不适合，你应就本身的投资经验，投资目标，财政资源及其他相关条件，小心衡量自己是否适合参与该等买卖。
  `,
  `
    尊敬的客户您好，我是陈归一，持有证监会发出的证券和期货交易牌照，我的牌照号码是BQD372，在您的帐户开通之前，请您细读我们的期货客户交易协议书和风险声明，我会简单为您说明一下风险声明的几个主要点，阁下需因应自身的投资经验、目的、财政或相关状况审慎考虑投资产品或服务是否适合您。如果途中您有不明白的地方或疑问，请您联系蜂投证券的客服，我们会进一步说明。
    客户作为专业投资者，本公司将不会再提供以下信息：
    1、须向客户提供有关本公司和有关其雇员及其他代表其行事的人士的身份和受雇状况的资料。
    2、为客户完成交易后，须尽快向该客户确认有关该宗交易的重点。
    3、须向客户提供关于纳斯达克-美国证券交易所试验计划的资料文件。
    您有权给予蜂投证券不少于14天的书面通知以反对被视为专业投资者及要求撤回专业投资者的资格。除非及直至蜂投证券收到您本人有关的反对及撤回资格之书面通知，蜂投证券有权将您视为专业投资者。
    客户做ISDA的风险：
    1、客户需要明白，ISDA并非普通证券业务，也不是在证券公司交易；客户需要签署“客户代理协议Agency Agreement”，由证券公司转介到其关联公司做交易。
    2、做 ISDA业务，股票将会在证券公司关联公司名下，而非客户名下，客户仅享有经济权益。
    3、客户的亏损如果达到预警线，必须追加保证金；如果达到平仓线，将会被强制平仓。
    客户做衍生品交易的风险：
    1、对手风险：当衍生工具的发行方是协力厂商，如上市公司或金融机构，如果发行方遇到财务问题，并导致发行方的信用评级下降；或者如果他们由于偿付能力问题而倒闭，衍生工具的价值将受到影响，甚至可能失去所有的价值。
    2、市场风险：衍生产品的价格取决于标的资产的价值。通常情况下，标的资产的价格波动将直接影响衍生产品的价格。
    3、流动风险：一些衍生产品在到期之前，可能难以出售和转换成现金。
    4、利率风险：利率的变化肯定会影响衍生产品的价值。
    5、杠杆风险：衍生权证价值的变化速度可能会比标的产品价值的变化速度要快或要慢。在最坏的情况下，衍生权证的价值可能会下降到零，持有人将失去他们的整个购买价格。
    期货及期权交易的风险：
    买卖期货合同或期权的亏损风险可以极大。在若干情况下，你所蒙受的亏损可能会超过最初存入的保证金数额。即使你设定了备用指示，例如"止损"或"限价"等指示，亦未必能够避免损失。市场情况可能使该等指示无法执行。你可能会在短时间内被要求存入额外的保证金。假如未能在指定的时间内提供所需数额，你的未平仓合同可能会被平仓。然而，你仍然要对你的帐户内任何因此而出现的短欠数额负责。因此，你在买卖前应研究及理解期货合同及期权，以及根据本身的财政状况及投资目标，仔细考虑这种买卖是否适合你。如果你买卖期权，便应熟悉行使期权及期权到期时的程序，以及你在行使期权及期权到期时的权利与责任。本声明并不涵盖买卖期货及期权的所有风险及其他重要事宜。就风险而言，你在进行任何上述交易前，应先了解将订立的合同的性质（及有关的合同关系）和你就此须承担的风险程度。期货及期权买卖对很多公众投资者都并不适合，你应就本身的投资经验，投资目标，财政资源及其他相关条件，小心衡量自己是否适合参与该等买卖。
  `,
  `
    尊敬的客户您好，我是陈归一，持有证监会发出的证券和期货交易牌照，我的牌照号码是BQD372，在您的账户开通之前，请您细读我们的客户协议书/期货客户交易协议书和风险声明，我会简单为您说明一下风险声明的几个主要点， 阁下需因应自身的投资经验，目的。财政或相关状况审慎考虑投资产品或服务是否适合您。如果途中您有不明白的地方或疑问，请您联系蜂投证券的客服，我们会进一步说明。
    客户作为证券交易客户，请注意以下风险：
    1、证券交易的风险：证券价格可升可跌，甚至变成毫无价值。
    2、创业板股份涉及很高的投资风险：创业板股份可能非常波动及流通性很低。公司既无盈利记录，也无可靠的未来盈利预测。有关创业板股份的数据可以在香港联合交易所有限公司的互联网站上找到。
    3、持牌人或注册人在香港以外地方收取或持有的客户资产，将可能不会享有赋予在香港收取或持有的客户资产的相同保障。
    4、如客户透过电子交易系统进行买卖， 就必须承受该电子服务任何系统上带来的风险， 包括有关系统硬件，软件，传输中断或停顿，通讯过程误解或错误等风险。
    5、认股权证（窝轮）及牛熊证是衍生产品（即是杠杆产品）风险较高，价格非常波动及流通量比较低，如牛熊证触及回收价，将实时被发行商强制收回。
    期货及期权交易的风险：
    买卖期货合同或期权的亏蚀风险可以极大。在若干情况下，你所蒙受的亏损可能会超过最初存入的保证金数额。即使你设定了备用指示，例如"止损"或"限价"等指示，亦未必能够避免损失。市场情况可能使该等指示无法执行。你可能会在短时间内被要求存入额外的保证金。假如未能在指定的时间内提供所需数额，你的未平仓合同可能会被平仓。然而，你仍然要对你的账户内任何因此而出现的短欠数额负责。因此，你在买卖前应研究及理解期货合同及期权，以及根据本身的财政状况及投资目标，仔细考虑这种买卖是否适合你。如果你买卖期权，便应熟悉行使期权及期权到期时的程序，以及你在行使期权及期权到期时的权利与责任。本声明并不涵盖买卖期货及期权的所有风险及其他重要事宜。就风险而言，你在进行任何上述交易前，应先了解将订立的合同的性质（及有关的合同关系）和你就此须承担的风险程度。期货及期权买卖对很多公众投资者都并不适合，你应就本身的投资经验，投资目标，财政资源及其他相关条件，小心衡量自己是否适合参与该等买卖。
  `,
  `
    尊敬的客户您好，我是陈归一，持有证监会发出的证券和期货交易牌照，我的牌照号码是BQD372，在您的帐户开通之前，请您细读我们的客户协议书和风险声明，我会简单为您说明一下风险声明的几个主要点， 阁下需因应自身的投资经验，目的。财政或相关状况审慎考虑投资产品或服务是否适合您。如果途中您有不明白的地方或疑问，请您联系蜂投证券的客服，我们会进一步说明。
    客户作为专业投资者，本公司将不会再提供以下信息：
    1、须向客户提供有关本公司和有关其雇员及其他代表其行事的人士的身份和受雇状况的资料。
    2、为客户完成交易后，须尽快向该客户确认有关该宗交易的重点。
    3、须向客户提供关于纳斯达克-美国证券交易所试验计划的资料文件。
    您有权给予蜂投证券不少于14天的书面通知以反对被视为专业投资者及要求撤回专业投资者的资格。除非及直至蜂投证券收到您本人有关的反对及撤回资格之书面通知，蜂投证券有权将您视为专业投资者。
    客户做ISDA的风险：
    1、客户需要明白，ISDA并非普通证券业务，也不是在证券公司交易；客户需要签署“客户代理协议Agency Agreement”，由证券公司转介到其关联公司做交易。
    2、做 ISDA业务，股票将会在证券公司关联公司名下，而非客户名下，客户仅享有经济权益。
    3、客户的亏损如果达到预警线，必须追加保证金；如果达到平仓线，将会被强制平仓。
    客户做衍生品交易的风险：
    对手风险：当衍生工具的发行方是协力厂商，如上市公司或金融机构，如果发行方遇到财务问题，并导致发行方的信用评级下降；或者如果他们由于偿付能力问题而倒闭，衍生工具的价值将受到影响，甚至可能失去所有的价值。
    市场风险：衍生产品的价格取决于标的资产的价值。通常情况下，标的资产的价格波动将直接影响衍生产品的价格。
    流动风险：一些衍生产品在到期之前， 可能难以出售和转换成现金。
    利率风险：利率的变化肯定会影响衍生产品的价值。
    杠杆风险：衍生权证价值的变化速度可能会比标的产品价值的变化速度要快或要慢。在最坏的情况下，衍生权证的价值可能会下降到零，持有人将失去他们的整个购买价格。
    客户作为证券交易客户，请注意以下风险：
    1、证券交易的风险：证券价格可升可跌，甚至变成毫无价值。
    2、创业板股份涉及很高的投资风险：创业板股份可能非常波动及流通性很低。公司既无盈利记录，也无可靠的未来盈利预测。有关创业板股份的资料可以在香港联合交易所有限公司的互联网站上找到。
    3、持牌人或注册人在香港以外地方收取或持有的客户资产，将可能不会享有赋予在香港收取或持有的客户资产的相同保障。
    4、如客户透过电子交易系统进行买卖， 就必须承受该电子服务任何系统上带来的风险， 包括有关系统硬体，软件，传输中断或停顿，通讯过程误解或错误等风险。
    5、认股权证（窝轮）及牛熊证是衍生产品（即是杠杆产品）风险较高，价格非常波动及流通量比较低，如牛熊证触及回收价，将即时被发行商强制收回。
  `,
  `
    尊敬的客户您好，我是陈归一，持有证监会发出的证券和期货交易牌照，我的牌照号码是BQD372，在您的帐户开通之前，请您细读我们的客户协议书和风险声明，我会简单为您说明一下风险声明的几个主要点， 阁下需因应自身的投资经验、目的、财政或相关状况审慎考虑投资产品或服务是否适合您。如果途中您有不明白的地方或疑问，请您联系蜂投证券的客服，我们会进一步说明。
    客户作为证券交易客户，请注意以下风险：
    1、证券交易的风险：证券价格可升可跌，甚至变成毫无价值。
    2、创业板股份涉及很高的投资风险：创业板股份可能非常波动及流通性很低。公司既无盈利记录，也无可靠的未来盈利预测。有关创业板股份的资料可以在香港联合交易所有限公司的互联网站上找到。
    3、持牌人或注册人在香港以外地方收取或持有的客户资产，将可能不会享有赋予在香港收取或持有的客户资产的相同保障。
    4、如客户透过电子交易系统进行买卖， 就必须承受该电子服务任何系统上带来的风险， 包括有关系统硬体、软件、传输中断或停顿，通讯过程误解或错误等风险。
    5、认股权证（窝轮）及牛熊证是衍生产品（即是杠杆产品）风险较高，价格非常波动及流通量比较低，如牛熊证触及回收价，将即时被发行商强制收回。
  `,
  `
    尊敬的客户您好，我是陈归一，持有证监会发出的证券和期货交易牌照，我的牌照号码是BQD372，在您的帐户开通之前，请您细读我们的客户协议书/期货客户交易协议书和风险声明，我会简单为您说明一下风险声明的几个主要点， 阁下需因应自身的投资经验，目的.财政或相关状况审慎考虑投资产品或服务是否适合您。如果途中您有不明白的地方或疑问，请您联系蜂投证券的客服，我们会进一步说明。
    客户作为专业投资者，本公司将不会再提供以下信息：
    1、须向客户提供有关本公司和有关其雇员及其他代表其行事的人士的身份和受雇状况的资料。
    2、为客户完成交易后，须尽快向该客户确认有关该宗交易的重点。
    3、须向客户提供关于纳斯达克-美国证券交易所试验计划的资料文件。
    您有权给予蜂投证券不少于 14 天的书面通知以反对被视为专业投资者及要求撤回专业投资者的资格。除非及直至蜂投证券收到您本人有关的反对及撤回资格之书面通知，蜂投证券有权将您视为专业投资者。
    客户做ISDA的风险：
    1、客户需要明白，ISDA并非普通证券业务，也不是在证券公司交易；客户需要签署“客户代理协议Agency Agreement”，由证券公司转介到其关联公司做交易。
    2、做 ISDA业务，股票将会在证券公司关联公司名下，而非客户名下，客户仅享有经济权益。
    3、客户的亏损如果达到预警线，必须追加保证金；如果达到平仓线，将会被强制平仓。
    客户做衍生品交易的风险：
    对手风险：当衍生工具的发行方是协力厂商，如上市公司或金融机构，如果发行方遇到财务问题，并导致发行方的信用评级下降；或者如果他们由于偿付能力问题而倒闭，衍生工具的价值将受到影响，甚至可能失去所有的价值。
    市场风险：衍生产品的价格取决于标的资产的价值。通常情况下，标的资产的价格波动将直接影响衍生产品的价格。
    流动风险：一些衍生产品在到期之前， 可能难以出售和转换成现金。
    利率风险：利率的变化肯定会影响衍生产品的价值。
    杠杆风险：衍生权证价值的变化速度 可能会比标的产品价值的变化速度要快或要慢。在最坏的情况下，衍生权证的价值可能会下降到零，持有人将失去他们的整个购买价格。
    客户作为证券交易客户，请注意以下风险：
    1、证券交易的风险：证券价格可升可跌，甚至变成毫无价值。
    2、创业板股份涉及很高的投资风险：创业板股份可能非常波动及流通性很低。公司既无盈利记录，也无可靠的未来盈利预测。有关创业板股份的资料可以在香港联合交易所有限公司的互联网站上找到。
    3、持牌人或注册人在香港以外地方收取或持有的客户资产，将可能不会享有赋予在香港收取或持有的客户资产的相同保障。
    4、如客户透过电子交易系统进行买卖， 就必须承受该电子服务任何系统上带来的风险， 包括有关系统硬体、软件、传输中断或停顿、通讯过程误解或错误等风险。
    5、认股权证（窝轮）及牛熊证是衍生产品（即是杠杆产品）风险较高，价格非常波动及流通量比较低，如牛熊证触及回收价，将即时被发行商强制收回。
    期货及期权交易的风险：
    买卖期货合同或期权的亏损风险可以极大。在若干情况下，你所蒙受的亏损可能会超过最初存入的保证金数额。即使你设定了备用指示，例如"止损"或"限价"等指示，亦未必能够避免损失。市场情况可能使该等指示无法执行。你可能会在短时间内被要求存入额外的保证金。假如未能在指定的时间内提供所需数额，你的未平仓合同可能会被平仓。然而，你仍然要对你的帐户内任何因此而出现的短欠数额负责。因此，你在买卖前应研究及理解期货合同及期权，以及根据本身的财政状况及投资目标，仔细考虑这种买卖是否适合你。如果你买卖期权，便应熟悉行使期权及期权到期时的程序，以及你在行使期权及期权到期时的权利与责任。本声明并不涵盖买卖期货及期权的所有风险及其他重要事宜。就风险而言，你在进行任何上述交易前，应先了解将订立的合同的性质（及有关的合同关系）和你就此须承担的风险程度。期货及期权买卖对很多公众投资者都并不适合，你应就本身的投资经验，投资目标，财政资源及其他相关条件，小心衡量自己是否适合参与该等买卖。
  `,
  `
    尊敬的客户您好，我是陈归一，持有证监会发出的证券和期货交易牌照，我的牌照号码是BQD372，在您的账户开通之前，请您细读我们的客户协议书/期货客户交易协议书和风险声明，我会简单为您说明一下风险声明的几个主要点，阁下需因应自身的投资经验、目的、财政或相关状况审慎考虑投资产品或服务是否适合您。如果途中您有不明白的地方或疑问，请您联系蜂投证券的客服，我们会进一步说明。

    客户作为专业投资者，本公司将不会再提供以下信息：
    1.须向客户提供有关本公司和有关其雇员及其他代表其行事的人士的身份和受雇情况的资料。
    2.为客户完成交易后，须尽快向该客户确认有关该宗交易的重点。
    3.须向客户提供关于纳斯达克-美国证券交易所试验计划的资料文件。

    客户作为证券交易客户，请注意以下风险：
    1.证券交易的风险：证券价格可升可跌，甚至变成毫无价值。
    2.创业板股份涉及很高的投资风险：创业板股份可能非常波动及流通性很低。公司既无盈利记录，也无可靠的未来盈利预测。有关创业板股份的资料可以在香港联合交易所有限公司的互联网站上找到。
    3.持牌人或注册人在香港以外地方收取或持有的客户资产，将可能不会享有赋予在香港收取或持有的客户资产的相同保障。
    4.如客户透过电子交易系统进行买卖, 就必须承受该电子服务任何系统上带来的风险, 包括有关系统硬件，软件，传输中断或停顿，通讯过程误解或错误等风险。
    5.认股权证（窝轮）及牛熊证是衍生产品（即是杠杆产品）风险较高，价格非常波动及流通量比较低，如牛熊证触及回收价，将即时被发行商强制收回。

    客户做ISDA的风险：
    1.客户需要明白，ISDA并非普通证券业务，也不是在证券公司交易；客户需要签署“客户代理协议Agency Agreement”，由证券公司转介到其关联公司做交易。
    2.做 ISDA业务，股票将会在证券公司关联公司名下，而非客户名下，客户仅享有经济权益。
    3.客户的亏损如果达到预警线，必须追加保证金；如果达到平仓线，将会被强制平仓。

    客户做衍生品交易的风险：
    对手风险：当衍生工具的发行方是协力厂商，如上市公司或金融机构，如果发行方遇到财务问题，并导致发行方的信用评级下降；或者如果他们由于偿付能力问题而倒闭，衍生工具的价值将受到影响，甚至可能失去所有的价值。
    市场风险：衍生产品的价格取决于标的资产的价值。通常情况下，标的资产的价格波动直接影响衍生产品的价格。
    流动风险：一些衍生产品在到期之前，可能难以出售和转换成现金。
    利率风险：利率的变化肯定会影响衍生产品的价值。
    杠杆风险：衍生权证价值的变化速度可能会比标的产品价值的变化速度要快或要慢。在最坏的情况下，衍生权证的价值可能会下降到零，持有人将失去他们的整个购买价格。

    期货及期权交易的风险
    买卖期货合同或期权的亏损风险可以极大。在若干情况下，你所蒙受的亏损可能会超过最初存入的保证金数额。即使你设定了备用指示，例如"止损"或"限价"等指示，亦未必能够避免损失。市场情况可能使该等指示无法执行。你可能会在短时间内被要求存入额外的保证金。假如未能在指定的时间内提供所需数额，你的未平仓合同可能会被平仓。然而，你仍然要对 你的账户内任何因此而出现的短欠数额负责。因此，你在买卖前应研究及理解期货合同及期权，以及根据本身的财政状况及投资目标，仔细考虑这种买卖是否适合你。如果你买卖期权，便应熟悉行使期权及期权到期时的程序，以及你在行使期权及期权到期时的权利与责任。本声明并不涵盖买卖期货及期权的所有风险及其他重要事宜。就风险而言，你在进行任何上述交易前，应先了解将订立的合同的性质（及有关的合同关系）和你就此须承担的风险程度。期货及期权买卖对很多公众投资者都并不适合，你应就本身的投资经验，投资目标，财政资源及其他相关条件，小心衡量自己是否适合参与该等买卖。
  `,
  ` 
    尊敬的客户您好，我是陈归一，持有证监会发出的证券和期货交易牌照，我的牌照号码是BQD372，在您的账户开通之前，请您细读我们的客户协议书/期货客户交易协议书和风险声明，我会简单为您说明一下风险声明的几个主要点，阁下需因应自身的投资经验、目的、财政或相关状况审慎考虑投资产品或服务是否适合您。如果途中您有不明白的地方或疑问，请您联系蜂投证券的客服，我们会进一步说明。

    客户作为证券交易客户，请注意以下风险：
    1.证券交易的风险：证券价格可升可跌，甚至变成毫无价值。
    2.创业板股份涉及很高的投资风险：创业板股份可能非常波动及流通性很低。公司既无盈利记录，也无可靠的未来盈利预测。有关创业板股份的资料可以在香港联合交易所有限公司的互联网站上找到。
    3.持牌人或注册人在香港以外地方收取或持有的客户资产，将可能不会享有赋予在香港收取或持有的客户资产的相同保障。
    4.如客户透过电子交易系统进行买卖, 就必须承受该电子服务任何系统上带来的风险, 包括有关系统硬件，软件，传输中断或停顿，通讯过程误解或错误等风险。
    5.认股权证（窝轮）及牛熊证是衍生产品（即是杠杆产品）风险较高，价格非常波动及流通量比较低，如牛熊证触及回收价，将即时被发行商强制收回。

    客户做衍生品交易的风险：
    对手风险：当衍生工具的发行方是协力厂商，如上市公司或金融机构，如果发行方遇到财务问题，并导致发行方的信用评级下降；或者如果由于他们偿付能力问题而倒闭，衍生工具的价值将受到影响，甚至可能失去所有的价值。
    市场风险：衍生产品的价格取决于标的资产的价值。通常情況下，标的资产的价格波动将直接影响衍生产品的价格。
    流动风险：一些衍生产品在到期之前，可能难以出售和转换成现金。
    利率风险：利率的变化肯定会影响衍生产品的价值。
    杠杆风险：衍生权证价值的变化速度可能会比标的产品价值的变化速度要快或要慢。在最坏的情況下，衍生权证的价值可能会下降到零，持有人将失去他们的整个购买价格。

    期货及期权交易的风险
    买卖期货合同或期权的亏损风险可以极大。在若干情況下，你所蒙受的亏损可能会超过最初存入的保证金数额。即使你设定了备用指示，例如"止损"或"限价"等指示，亦未必能够避免损失。市场情况可能使该等指示无法执行。你可能会在短时间内被要求存入额外的保证金。假如未能在指定的时间内提供所需数额，你的未平仓合同可能会被平仓。然而，你仍然要对你的账户內任何因此而出现的短欠数额负责。因此，你在买卖前应研究及理解期货合同及期权，以及根据本身的财政状况及投资目标，仔细考虑这种买卖是否适合你。如果你买卖期权，便应熟悉行使期权及期权到期时的程序，以及你在行使期权及期权到期时的权利与责任。本声明并不涵盖买卖期货及期权的所有风险及其他重要事宜。就风险而言，你在进行任何上述交易前，应先了解将订立的合同的性质（及有关的合同关系）和你就此须承担的风险程度。期货及期权买卖对很多公众投资者都并不适合，你应就本身的投资经验，投资目标，财政资源及其他相关条件，小心衡量自己是否适合参与该等买卖。
  `,
].map((v) => v.replace(/\r?\n/g, '<br />'));
const defaultStr = [
  {
    label: 'risk_tell',
    showName: '风险披露已收听',
    type: 'str',
    value: '是',
  },
  {
    label: 'risk_tell_type',
    showName: '风险披露类型',
    type: 'arr',
    value: [],
  },
];
export default {
  name: 'Home',
  components: {
    [Button.name]: Button,
    [CellGroup.name]: CellGroup,
    [VanImage.name]: VanImage,
    [Slider.name]: Slider,
  },
  data() {
    return {
      speed: 1,
      contentList,
    };
  },
  methods: {
    async next() {
      if (this.disabledNext.value) return;
      const httprequest = this.isAddAccount
        ? httpAddApi.updateRiskTellStatus : httpApi.postRiskDisclosurStepOne;
      if (this.isAddAccount) {
        const list = this.originData && this.originData[1] ? this.originData[1].value : [];
        let arr = list.filter((v) => v !== this.form.risk_tell_type);
        if (this.form.risk_tell_type === 3) {
          this.originData[1].value = [3];
        } else {
          arr = arr.filter((v) => v !== 3);
          arr.push(this.form.risk_tell_type);
          this.originData[1].value = arr;
        }
      } else {
        this.originData[1].value = [this.form.risk_tell_type];
      }
      const result = await httprequest(this.originData);
      if (result) {
        this.$router.push({ path: '/riskDisclosure/step_two' });
      }
    },
    onSliderChange() {
      // if (!localStorage.getItem('voice-slider')) return;
      // this.currentTime = (this.duration * value) / 100;
      // this.$refs.audio.currentTime = this.currentTime;
    },
    before() {
      this.$router.back();
    },

    secondToDate,

    onAudioCanplay() {
      this.canPlay = true;
    },

    onAudioPlaying() {
      this.isPlaying = true;
    },

    onAudioPause() {
      this.isPlaying = false;
    },

    onAudioEnd() {
      this.playEnd = true;
      this.isPlaying = false;
      this.currentTime = this.duration;
    },

    onAudioTimeupdate(e) {
      this.currentTime = e.target.currentTime;
    },

    onAudioDurationChange(e) {
      this.duration = e.target.duration;
    },

    onAudioPlay() {
      if (this.isPlaying) {
        this.$refs.audio.pause();
        this.isPlaying = false;
      } else {
        this.$refs.audio.play();
        this.isPlaying = true;
      }
    },

    handleSpeed(speed) {
      this.speed = Number(speed);
      this.$refs.audio.playbackRate = speed;
    },
  },
  setup() {
    const currentTime = ref(0);
    const duration = ref(0);
    const canPlay = ref(true);
    const playEnd = ref(false);
    const isPlaying = ref(false);
    const audioValue = computed(() => (currentTime.value / duration.value) * 100);
    const form = reactive({
      risk_tell: '',
      risk_tell_type: '',
    });
    const originData = ref('');
    const disabledNext = ref(true);
    const store = useStore();
    const isAddAccount = computed(() => store.state.user.isAddAccount);
    watch(audioValue, async (value) => {
      if (value >= 100) {
        disabledNext.value = false;
        form.risk_tell = '是';
        httpApi.log('听完了', JSON.parse(JSON.stringify(form)));
        // 听完录音日志
        if (isAddAccount.value) await httpApi.submitLog({ type: `submitAddRiskTellStatus${form.risk_tell_type}` });
        else await httpApi.submitLog({ type: 'submitRiskTellStatus' });
      }
    });
    onMounted(async () => {
      store.commit('setTitleValue', '风险认知');
      if (isAddAccount.value) {
        store.commit('setStepsValue', ['风险认知(4/4)', '提交资料']);
        store.commit('setStepsActive', 0);
      } else {
        store.commit('setStepsValue', ['填写资料', '风险认知(4/4)', '提交资料']);
        store.commit('setStepsActive', 1);
      }
      const httprequest = isAddAccount.value
        ? httpAddApi.getRiskTellStatus : httpApi.getRiskDisclosurStepOne;
      const result = await httprequest();
      if (!result) return;
      originData.value = result.data || defaultStr;
      const httprequest2 = isAddAccount.value
        ? httpAddApi.getVideoType : httpApi.getVideoType;
      const result2 = await httprequest2();
      form.risk_tell_type = result2.data.risk_tell_type;
      // 禁用状态下处理risk_tell_type
      const isDisabled = await httpApi.getDisabled();
      if (isDisabled && result.data[1].value.length && !isAddAccount.value) {
        [form.risk_tell_type] = result.data[1].value;
      }
      if (form.risk_tell_type === 3) {
        const { isEnterAccount, isEnterMajor } = store.state.user;
        if (!isEnterAccount) form.risk_tell_type = 1;
        if (!isEnterMajor) form.risk_tell_type = 2;
      }
      if (result.data && result.data[1].value.indexOf(form.risk_tell_type) > -1) {
        form.risk_tell = '是';
      }
      if (form.risk_tell === '是') {
        disabledNext.value = false;
      }
    });

    return {
      form,
      isAddAccount,
      originData,

      currentTime,
      duration,
      canPlay,
      playEnd,
      isPlaying,
      audioValue,

      disabledNext,
    };
  },
};
</script>

<style lang="less">
  .risk-root {
    text-align: left;
    top: 107px;
    height: calc(100vh - 107px);
    bottom: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .center {
      flex-shrink: 2;
      overflow: scroll;
    }
    .top {
      padding-bottom: 10px;
    }
    .content {
      margin-top: @space-2;
      padding: @space-2;
      background: @contentColor;
      font-size: @font-size-2;
    }
    .bottom {
      padding-bottom: 20px;
    }

    .title-wraper {
      display: flex;
      justify-content: space-between;
      .title {
        color: rgba(0, 0, 0, 0.4);
      }
      .span {
        padding: 3px;
        cursor: pointer;
        border: 1px solid rgba(0, 0, 0, 0.3);
        border-right: none;
      }
      .span:last-child {
        border-right: 1px solid rgba(0, 0, 0, 0.3);
      }
      .active {
        color: @primary;
      }
    }

    .title {
      text-align: center;
    }

    .represent  {
      display: flex;
      align-items: center;
      padding: @space-4 @space-2;

      .image {
        display: flex;
        align-items: center;
      }

      .name {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 100px;
        padding: 0 @space-3;
      }
    }

    .audio {
      margin-top: @space-2;
      padding: 0 @space-3;
      display: flex;
      align-items: center;

      .playBtn {
        margin-right: @space-3;
        width: 30px;
        height: 30px;
      }

      .audioSlider {
        flex: 1;
        margin: 0 @space-3;
        line-height: 18px;
      }
    }
    .btn {
      width: 80%;
      margin: @space-0 auto;
      margin-bottom: 0;
    }
  }
</style>
